import './App.css';
import ImagesExample from './components/ImagesExample';
import Quilll from './components/Quilll';
import SlateComponent from './components/SlateComponent';

function App() {
  return (
    <div className="App">
    <ImagesExample/>
    </div>
  );
}

export default App;
